<template lang="pug">
  .crm-textarea(:class="{'crm-textarea_v2': v2, 'crm-textarea--error': error}")
    base-label(:required="required" :label="label")
    textarea(
      v-model.lazy="inputValue" 
      :placeholder="placeholder" 
      :maxlength="maxlength" 
      :required="required"
      :disabled="disabled"
      :rows="rows"
      ref="textarea"
      @input="onInput"
      @keyup="resize"
      @blur="$emit('blur', $event)"
    )
    img(src="@/assets/img/ui/close.svg" v-if="showCloseMenuBtn" @click="$emit('clear', $event)").close-icon
    span.error-message {{ errorMessages }}
</template>

<script>
export default {
  name: 'crmTextarea',

  props: {
    label: String,
    value: String,
    placeholder: String,
    maxlength: Number,
    error: Boolean,
    errorMessages: String,
    disabled: Boolean,
    v2: Boolean,
    rows: {
      default: '5',
      type: [String, Number],
    },
    required: {
      type: Boolean,
      default: false
    },
    showCloseMenuBtn: {
      type: Boolean,
      default: false
    },
    autoHeight: Boolean
  },

  computed: {
    inputValue: {
      get: function () {
        return this.value
      },
      set: function (value) {
        this.$emit('input', value)
        this.$emit('change', value)
      }
    },
  },

  methods: {
    onInput(event) {
      this.$emit('input', event.target.value)
      this.resize()
    },
    resize(event) {
      if(!this.autoHeight) return
      event.target.style.height = "auto";
      event.target.style.height = `${event.target.scrollHeight}px`;
    }
  },
  mounted() {
    if(this.autoHeight) this.$refs.textarea.dispatchEvent(new Event('keyup'))
  },

  components: {
    baseLabel: () => import('@/components/global/actions/BaseControlLabel.vue')
  }
}
</script>

<style lang="scss">
.crm-textarea {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;

  &-- {
    &error {
      textarea {
        border-color: $error-color !important;
      }
    }
  }

  textarea {
    padding: 12px;
    // min-height: 100px;
    width: 100%;
    border: solid 1px $border-color;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 6px 0 rgb(0 0 0 / 5%);
    color: #232428;
    letter-spacing: normal;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    line-height: normal;
    resize: none;
    transition: 0.2s;

    caret-color: #4E7FED;

    &::placeholder {
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      font-stretch: normal;
      font-size: 12px;
      font-size: 12px;
      font-family: "Roboto", sans-serif;
      font-family: "Roboto", sans-serif;
      line-height: normal;
    }

    &:focus {
      outline: #4E7FED;
      border-color: #4E7FED;
    }
  &:disabled {
      background-color: #F2F4F7;
      opacity: 1;
      -webkit-text-fill-color: #232428;
    }
  }

  .close-icon {
    position: absolute;
    top: -10px;
    right: -10px;
    left: auto;
    display: block;
    width: 26px;
    height: 26px;
    transition: opacity 0.3s ease-in-out;
    cursor: pointer;
  }

  .error-message {
    top: auto;
    bottom: -2px;
  }
  &_v2 {
    textarea {
      padding: 10px 8px;
      font-weight: 500;
      font-size: 12px;
      font-family: $font;
    }
  }
}

</style>
